<template>
    <div>
        <h3 class="text-lg font-semibold"><slot name="default"></slot></h3>
        <a
            v-if="isFilterApplied"
            class="text-xs hover:font-medium hover:underline"
            href="#"
            @click.prevent="clearFilter"
            >clear</a
        >
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { computed } from 'vue';

const props = defineProps({
    filterKey: {
        type: String,
        required: true,
    },
});

const $emit = defineEmits(['filterCleared']);

const { appliedFilters, queueFilter } = useCollectionFilters();

const isFilterApplied = computed(() => appliedFilters.value[props.filterKey]);

const clearFilter = () => {
    queueFilter(props.filterKey, null);
    $emit('filterCleared');
};
</script>

<style scoped></style>
