<template>
    <div>
        <h3 class="mb-6 text-lg font-semibold"></h3>
        <clearable-filter-header
            class="mb-6 flex items-center space-x-2"
            filter-key="lang"
            @filter-cleared="selectedLanguages = []"
            >Supported languages</clearable-filter-header
        >

        <ul class="grid grid-cols-1 gap-y-3 text-sm at720:grid-cols-2">
            <li class="flex flex-row items-center space-x-4">
                <input
                    type="checkbox"
                    value="en"
                    class="rounded border-gray-300 !bg-gray-300 accent-gray-300"
                    :checked="selectedLanguages.length === 0"
                    readonly
                    disabled
                />
                <span>English</span>
            </li>
            <li
                v-for="(language, idx) in languages"
                class="flex flex-row items-center space-x-4"
            >
                <input
                    :id="`modal-filter-language-${idx}`"
                    @change="handleSelection(language.value, $event)"
                    type="checkbox"
                    :value="language.value"
                    class="rounded border-gray-300"
                    :checked="selectedLanguages.indexOf(language.value) > -1"
                />
                <label :for="`modal-filter-language-${idx}`">{{
                    language.label
                }}</label>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import ClearableFilterHeader from 'components/ProductList/ProductFilter/ModalFilters/ClearableFilterHeader.vue';
import { ref } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const { filterOptions, queueFilter } = useCollectionFilters();
const languages = filterOptions.languages;
const selectedLanguages = ref(props.current ? [...props.current] : []);

const handleSelection = (language, event) => {
    if (event.target.checked) {
        selectedLanguages.value.push(language);
        selectedLanguages.value.sort();
    } else {
        try {
            let index = selectedLanguages.value.indexOf(language);
            if (index !== -1) {
                selectedLanguages.value.splice(index, 1);
            }
        } catch (e) {}
    }
    queueFilter('lang', selectedLanguages.value);
};
</script>

<style scoped></style>
