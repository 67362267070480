<template>
    <div v-if="intlShippingRates.length > 0">
        <h5 class="font-semibold">International shipping destinations</h5>
        <div class="my-3 rounded border border-gray-300 p-5 pb-4">
            <h6 class="text-xs font-semibold uppercase">
                Country + estimated shipping cost
            </h6>
            <table class="mt-3 w-full">
                <tr v-for="rates in intlShippingRates">
                    <td
                        v-for="rate in rates"
                        class="first:border-r first:border-gray-300 first:pr-4 last:pl-4"
                    >
                        <div
                            class="flex items-baseline space-x-2 border-t border-gray-300 py-2.5"
                        >
                            <span>{{ rate.country }}</span>
                            <span class="text-xs text-gray-500"
                                >+ {{ formatCurrency(rate.minPrice) }}
                                <template
                                    v-if="
                                        rate.maxPrice &&
                                        rate.maxPrice !== rate.minPrice
                                    "
                                    >to
                                    {{
                                        formatCurrency(rate.maxPrice)
                                    }}</template
                                >
                            </span>
                        </div>
                    </td>
                    <td v-if="rates.length === 1" class="pl-4">
                        <div
                            class="flex items-baseline space-x-2 border-t border-gray-300 py-2.5"
                        >
                            &nbsp;
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <p class="text-xs text-gray-500">
            {{
                intlShippingPolicy ||
                'International shipping will be billed after booking. We may need the addresses earlier in order to deliver items on time so please submit the addresses as early as possible.'
            }}
        </p>
    </div>
    <div v-else-if="hostIntlShippingCountries.length > 0">
        <div class="font-semibold">
            <span
                >International shipping is available to the following
                {{
                    hostIntlShippingCountries.length > 1
                        ? 'destinations'
                        : 'destination'
                }}:</span
            >
        </div>
        <expandable-set
            :limit="20"
            :items="hostIntlShippingCountries"
            class="mt-4"
        ></expandable-set>
    </div>
</template>
<script setup>
import { formatCurrency } from '@/helpers/priceFormatters';
import ExpandableSet from 'components/Global/ExpandableSet.vue';
import { computed } from 'vue';

const props = defineProps({
    intlShippingRates: {
        type: Array,
        required: true,
    },
    intlShippingPolicy: {
        type: String,
        required: false,
    },
    hostIntlShippingCountries: {
        type: Object,
        required: true,
    },
});

// intl shipping rates set on the product override host intl shipping countries
const intlShippingRates = computed(() => {
    const rates = props.intlShippingRates;
    if (rates.length === 0) {
        return [];
    }
    // split rates into rows of 2
    const rows = [];
    rates.forEach((rate, index) => {
        const x = index % 2;
        const y = Math.floor(index / 2);
        if (!rows[y]) {
            rows[y] = [];
        }
        rows[y][x] = rate;
    });
    return rows;
});

const hostIntlShippingCountries = computed(() =>
    props.hostIntlShippingCountries
        ? Object.values(props.hostIntlShippingCountries)
        : []
);
</script>
